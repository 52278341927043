import React, { useCallback, useEffect, useState } from 'react';
import API from 'src/services/API';

export default function Crawler() {
  const [data, setData] = useState();

  const _fun = useCallback(async () => {
    let response = await API.crawler();
    setData(response?.data || []);
  }, []);

  useEffect(() => {
    _fun();
  }, []);

  return (
    <div style={{ display: 'none' }}>
      {data?.map((obj) => {
        return (
          <a href={`https://certyiq.com/papers/${obj?.stream}/${obj?.paperid}/1`}>{`${obj?.stream?.toUpperCase()} ${
            obj?.name
          }, Premium Exam Material with PDF Real Exam Questions | CertyIQ`}</a>
        );
      })}

      {data?.map((obj) => {
        return (
          <a
            href={`https://certyiq.com/practice-test/${obj?.stream}/${obj?.paperid}`}
          >{`${obj?.stream?.toUpperCase()} ${obj?.name}, Premium Practice Test Real Exam Questions | CertyIQ`}</a>
        );
      })}
    </div>
  );
}
