
export const freeHeadings = [
    " Unlimited public/private repositorie",
    "Automatic security and version updates",
    "2,000 CI/CD minutes/month",
    "500MB of Packages storage",
    "New issues & projects (in limited beta)",
    " Community support",

];

export const freedescrip = [
    'Host open source projects in public GitHub repositories, accessible via web or command line. Public repositories are accessible to anyone at GitHub.com.',
    'Keep projects secure by automatically opening pull requests that update vulnerable dependencies to secure versions, and update out-of-date dependencies.',
    'Use execution minutes with GitHub Actions to automate your software development workflows. Write tasks and combine them to build, test, and deploy any code project on GitHub.',
    'Host your own software packages or use them as dependencies in other projects. Both private and public hosting available.',
    'Give your developers flexible features for project management that adapts to any team, project, and workflow — all alongside your code.',
    'Get help with most of your GitHub questions and issues in our Community Forum.',
];

export const premiumHeadings = [
    " Access to GitHub Codespaces",
    "Protected branches",
    "Multiple reviewers in pull requests",
    "Draft pull requests",
    "Code owners",
    "Required reviewers",
    "Pages and Wikis",

];

export const premiumdescrip = [
    'Blazing fast cloud developer environments with flexible compute and pre-configured containers, developers can code, collaborate, and debug from any browser. Pay only for what you use with compute fees starting at $0.18/hr and storage fees at $0.07/GB per month.',
    'Enforce restrictions on how code branches are merged, including requiring reviews by selected collaborators, or allowing only specific contributors to work on a particular branch.',
    'Assign multiple users or a team to review a pull request.',
    'Easily discuss and collaborate on pull requests before submitting to formal review.',
    'Automatically request reviews—or require approval—by selected contributors when changes are made to sections of code that they own.',
    'Ensure that pull requests have a specific number of approving reviews before collaborators can make changes to a protected branch.',
];

export const enterpriseHeadings = [
    "Enterprise Managed Users",
    "User provisioning through SCIM",
    "Enterprise Account to centrally manage multiple organizations",
    "Environment protection rules ",
    "Audit Log API",
    "SOC1, SOC2, type 2 reports annually",
    "FedRAMP Tailored Authority to Operate (ATO)",

];

export const enterprisedescrip = [
    'Own and control the user accounts of your enterprise members through your identity provider (IdP).',
    "Automatically invite members to join your organization when you grant access on your IdP. If you remove a member's access to your GitHub organization on your SAML IdP, the member will be automatically removed from the GitHub organization.",
    'GitHub Enterprise Cloud includes the option to create an enterprise account, which enables collaboration between multiple organizations, gives administrators a single point of visibility and management and brings license cost savings for identical users in multiple organizations.',
    "When a workflow job references an environment, the job won't start until all of the environment's protection rules pass.",
    'As a GitHub Enterprise Cloud organization administrator, you can now access log events using our GraphQL API and monitor the activity in your organization.',
    'GitHub offers AICPA System and Organization Controls (SOC) 1 Type 2 and SOC 2 Type 2 reports with IAASB International Standards on Assurance Engagements, ISAE 3000, and ISAE 3402.',
]; 