import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import countryToCurrency from 'country-to-currency';
import { priceSupportedDecimals } from 'src/config';
import API from '../services/API';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  country: null,
  userCurrency: null,
  isAdmin: false,
  streams: [],
  loginModelOpen: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isAdmin: user?.role?.includes('adm') || false
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      isAdmin: user?.role?.includes('adm') || false
    };
  },
  COUNTRY: (state, action) => {
    const { details } = action.payload;
    return {
      ...state,
      country: details
    };
  },
  LOGIN_MODAL: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      loginModelOpen: data
    };
  },
  USER_CURRENCY: (state, action) => {
    const { details } = action.payload;
    return {
      ...state,
      userCurrency: details
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isAdmin: false
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      isAdmin: user?.role?.includes('adm')
    };
  },
  STREAMS: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      streams: data || []
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  setDiscount: () => Promise.resolve(),
  loginModal: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);
          API.setToken(accessToken);
          const userResonse = await API.getUserDetails(accessToken);
          const user = userResonse.data;

          if (user?.accountuuid)
            API.setDeviceUUID(user?.accountuuid)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, otp, referral) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await API.confirmOTP(email, otp, referral);
        localStorage.removeItem('referral');
        API.setToken(response?.token);

        const { token } = response;
        let data = {};

        if (response?.data?.accountuuid) {
          API.setDeviceUUID(response?.data?.accountuuid)
        }

        if (response?.data?.user) {
          data = response?.data?.user
        } else {
          const userResponse = await API.getUserDetails(response?.token);
          data = userResponse?.data;
        }

        setSession(token);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: data
          }
        });
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  };

  const googleLogin = async (access_token, referralCode, type = 'google') => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await API.googleLogin(access_token, referralCode, type);
        localStorage.removeItem('referral');
        API.setToken(response?.token);

        const { token } = response;
        let data = {};

        if (response?.data?.accountuuid) {
          API.setDeviceUUID(response?.data?.accountuuid)
        }

        if (response?.data?.user) {
          data = response?.data?.user
        } else {
          const userResponse = await API.getUserDetails(response?.token);
          data = userResponse?.data;
        }

        setSession(token);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: data
          }
        });
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  };

  const getCountryDetails = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let localDetails = localStorage.getItem('userC');
        try {
          if (localDetails) localDetails = JSON.parse(localDetails);
        } catch { }
        // let localDetails = null;
        let userResonse;
        if (!localDetails) {
          userResonse = await API.getUserCountryDetails();
          userResonse = userResonse?.location?.country?.code;
          if (userResonse) localStorage.setItem('userC', JSON.stringify(userResonse));
        } else {
          userResonse = localDetails;
        }

        const _fiatCurrency = await API.getFiatCurrency();
        const { data = {} } = _fiatCurrency;
        // const countryCode = userResonse?.location2 || userResonse?.location?.country || "IN";
        const countryCode = userResonse || 'IN';
        const countryCurrency = countryToCurrency[countryCode];
        const userCurrency = data[countryCurrency] || data['USD'] || {};
        userCurrency['discountPrice'] = countryCode === 'IN' ? 499 : Number((userCurrency?.usd_rate * 9).toFixed((userCurrency?.usd_rate * 9) > priceSupportedDecimals ? 0 : 2));
        userCurrency['discountPriceUSD'] = Number((499 / userCurrency?.usd_rate).toFixed((499 / userCurrency?.usd_rate) > priceSupportedDecimals ? 0 : 2));
        userCurrency['price'] = countryCode === 'IN' ? 1499 : Number((userCurrency?.usd_rate * 25).toFixed((userCurrency?.usd_rate * 25) > priceSupportedDecimals ? 0 : 2));
        dispatch({
          type: 'USER_CURRENCY',
          payload: {
            details: userCurrency
          }
        });
        API.setCountry(countryCode);
        dispatch({
          type: 'COUNTRY',
          payload: {
            details: userResonse
          }
        });
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  };

  const setDiscount = async (percentage, voucherCode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const userCurrency = { ...state.userCurrency };
        const voucherDiscountedPrice = Number(
          (userCurrency?.discountPrice - (percentage / 100) * userCurrency?.discountPrice).toFixed(2)
        );
        userCurrency['voucherDiscountPrice'] = percentage
          ? state.country === 'IN'
            ? voucherDiscountedPrice
            : Number((userCurrency?.usd_rate * 9).toFixed(2))
          : null;
        userCurrency['voucherDiscountPriceUSD'] = percentage
          ? Number((voucherDiscountedPrice / userCurrency?.usd_rate).toFixed(2))
          : null;

        userCurrency['voucherCode'] = voucherCode;

        dispatch({
          type: 'USER_CURRENCY',
          payload: {
            details: userCurrency
          }
        });
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const getStreams = async (adminView) => {
    const response = await API.getStreams({ adminView: adminView || undefined });
    dispatch({
      type: 'STREAMS',
      payload: {
        data: response?.data || []
      }
    });
  };

  const logout = async () => {
    API.setToken('');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const loginModal = (value = false) => {
    dispatch({
      type: 'LOGIN_MODAL',
      payload: {
        data: value
      }
    });
  }

  const resetPassword = () => { };

  const updateProfile = async (values) => {
    try {
      const resposne = await API.updateProfile({ ...values });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        googleLogin,
        logout,
        register,
        resetPassword,
        updateProfile,
        getCountryDetails,
        getStreams,
        setDiscount,
        loginModal
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
