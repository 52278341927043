import { useLocation } from 'react-router-dom';
// material
import { Alert, Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
// components
import googleFill from '@iconify/icons-eva/google-fill';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import React, { useEffect, useState } from 'react';
import GitHubLogin from 'react-github-login';
import { LoginSocialGoogle, LoginSocialLinkedin } from 'reactjs-social-login';
import { DialogAnimate } from 'src/components/animate';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

import closeFill from '@iconify/icons-eva/close-fill';

// ----------------------------------------------------------------------


const ContentStyle = styled('div')(({ theme }) => ({
    width: '85%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    paddingBottom:  theme.spacing(5),
}));

// ----------------------------------------------------------------------

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginModel() {
    const { method, login, googleLogin, loginModelOpen, loginModal, user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    let query = useQuery();
    const ref = query.get('ref');
    const [referralCode, setReferralCode] = useState(ref);
    const [googleResponse, setGoogleResponse] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const _ref = query.get('ref');
        const _referal = localStorage.getItem('referral');
        if (_ref?.length === 7 && _ref !== "*******") {
            localStorage.setItem('referral', _ref);
            setReferralCode(_ref);
        } else if (_referal?.length && _referal !== "*******") {
            setReferralCode(_referal);
        }
    }, []);

    const handleLoginAuth0 = async () => {
        try {
            await login();
        } catch (error) {
            console.error(error);
        }
    };

    const responseGoogle = (e) => {
        setGoogleResponse(e);
    };

    useEffect(() => {
        _responseGoogle(googleResponse, referralCode);
    }, [googleResponse]);

    const _responseGoogle = async (response, referralCode) => {
        if (response?.data?.access_token) {
            setIsLoading(true);
            await googleLogin(response?.data?.access_token, referralCode?.length === 7 ? referralCode : undefined, response?.provider || 'google');
            setGoogleResponse(null);
            setIsLoading(false);
        } else {
            console.log('error');
        }
    };

    const responseGoogleFail = (response) => {
        enqueueSnackbar('Please use different login method', { variant: 'error' });
        console.log(response?.message)
    };


    const onSuccess = async (response) => {
        if (response?.code) {
            setIsLoading(true);
            await googleLogin(response?.code, referralCode?.length === 7 ? referralCode : undefined, 'github');
            setGoogleResponse(null);
            setIsLoading(false);
        }
    };
    const onFailure = response => {
        enqueueSnackbar('Please use different login method', { variant: 'error' });
        console.log(response?.message)
    };

    const handleClose = () =>{
        loginModal(false)
    }

    useEffect(()=>{
        if(user){
            loginModal(false)
        }
    },[user])

    return (
        <DialogAnimate maxWidth="sm" animate={true} open={loginModelOpen} onClose={handleClose}>
            <Box sx={{ flexGrow: 1, alignItems: 'center', p: 1 }}>
                <Box sx={{ position: 'absolute', right: '10px' }}>
                    <Tooltip title="Close">
                        <IconButton color="inherit" onClick={handleClose}>
                            <Icon icon={closeFill} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <ContentStyle>
                <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" gutterBottom>
                            Welcome to CertyIQ
                        </Typography>
                        {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
                    </Box>

                    {/* <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
                </Stack>

                {/* {method === 'firebase' && <AuthFirebaseSocials />} */}

                {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@certyiq.com</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}

                {method !== 'auth0' ? (
                    <LoginForm setReferralCode={(value) => setReferralCode(value)} />
                ) : (
                    <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                        Login
                    </Button>
                )}

                <Divider sx={{ my: 3, borderStyle: 'dashed' }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} role="separator">
                        OR
                    </Typography>
                </Divider>

                {/* <Alert severity="warning" sx={{ mb: 3 }}>
            Note: Google login is currently undergoing maintenance. Please use email login in the meantime.
          </Alert> */}

                <Stack direction="row" spacing={5} justifyContent={"center"}>
                    <LoginSocialGoogle
                        isOnlyGetToken
                        client_id={'368857289649-3cevbh2ibse7ldi61i2lf9mrcm5gcdkt.apps.googleusercontent.com'}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onResolve={responseGoogle}
                        onReject={responseGoogleFail}
                    >
                        {!isLoading && <Box sx={{ cursor: "pointer" }}>
                            <Icon icon={googleFill} color="#DF3E30" height={30} />
                        </Box>}
                    </LoginSocialGoogle>
                    {!isLoading &&
                        <GitHubLogin clientId="fd9afabc78d18585e032"
                            redirectUri="https://certyiq.com/auth/login"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            className="github-login-button"
                        >

                            <img
                                src="https://cdn-icons-png.flaticon.com/512/25/25231.png"
                                height={"30px"}
                                alt="github-login-logo"
                            />
                        </GitHubLogin>
                    }

                    <LoginSocialLinkedin
                        isOnlyGetToken
                        client_id={"77wuw4lbp7jgnj"}
                        client_secret={'eL1EaNcvbJXF0FLy'}
                        redirect_uri={'https://certyiq.com/auth/login'}
                        scope="email profile openid"
                        onResolve={responseGoogle}
                        onReject={responseGoogleFail}
                    >
                        {!isLoading &&
                            <button className="github-login-button">
                                <img
                                    src="https://cdn-icons-png.flaticon.com/256/174/174857.png"
                                    height={"30px"}
                                    alt="github-login-logo"
                                />
                            </button>
                        }
                    </LoginSocialLinkedin>

                </Stack>

                <Alert sx={{ mt: 5 }} severity="info">
                     CertyIQ will never disclose any of your details with anyone else
                </Alert>

                <MHidden width="smUp">
                    <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                        {/* Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link> */}
                    </Typography>
                </MHidden>
            </ContentStyle>
        </DialogAnimate>
    );
}
