// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
import GoogleAnalytics from './components/GoogleAnalytics';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemeLocalization from './components/ThemeLocalization';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import Settings from './components/settings';
// import { useDispatch } from 'react-redux';
// import { getProfileCountryDetails } from './redux/slices/user';
import { useEffect } from 'react';
import UserAnalyser from './components/analytics/userAnalyser';
import LoginModel from './pages/authentication/LoginModel';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, getCountryDetails, isAdmin, getStreams, loginModelOpen, user } = useAuth();

  useEffect(() => {
    getCountryDetails();
  }, []);

  useEffect(() => {
    getStreams(isAdmin)
  }, [isAdmin, user])

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              {loginModelOpen &&
                <LoginModel />
              }
              <UserAnalyser />
              <Settings />
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
