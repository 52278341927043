import { useEffect } from 'react'
import API from 'src/services/API'

export default function UserAnalyser() {
    const getSession = async () => {
        const id = localStorage.getItem("c_uuid")
        if (!id) {
            const response = await API.getDeviceToken()
            if (response?.data?.uuid) {
                API.setDeviceUUID(response?.data?.uuid)
                localStorage.setItem("c_uuid", response?.data?.uuid)
            }
        } else {
            API.setDeviceUUID(id)
        }

        let userCountry = localStorage.getItem('userC')
        if (userCountry) {
            if (userCountry) userCountry = JSON.parse(userCountry);
            API.setCountry(userCountry)
        }
    }

    useEffect(() => {
        getSession()
    }, [])

    return null
}
