import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import youtubeFill from '@iconify/icons-ant-design/youtube-filled';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import twitterFilled from '@iconify/icons-ant-design/twitter';
import facebookFilled from '@iconify/icons-eva/facebook-fill';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'Youtube', icon: youtubeFill,   link: "https://www.youtube.com/@certyiq/videos?sub_confirmation=1" },
  { name: 'Instagram', icon: instagramFilled, link: "https://www.instagram.com/certyiq/" },
  { name: 'Linkedin', icon: linkedinFill,  link: "https://www.linkedin.com/company/certyiq-company/posts/?feedView=all" },
  { name: 'Twitter', icon: twitterFilled,  link: "https://twitter.com/certyiq_" },
  { name: 'Facebook', icon: facebookFilled,  link: "https://www.facebook.com/certyiq" },
];

const LINKS = [
  {
    headline: 'CertyIQ',
    children: [
      { name: 'Premium Access', href: PATH_PAGE.paymentmethod  },
      { name: 'About us', href: PATH_PAGE.about },
      // { name: 'Plan', href: PATH_PAGE.plan },
      { name: 'FAQs', href: PATH_PAGE.faqs }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacy  },
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'certyiqofficial@gmail.com', href: '/contact-us' },
      { name: 'New Exam Request', href: PATH_PAGE.request },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'Feedback', href: PATH_PAGE.feedback }
      
      // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' }
      // { name: '+91 7206300476', href: 'tel:917206300476' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              We are the biggest and most updated IT certification exam material website.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <a href={social?.link} target="_blank">
                  <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                </a>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
