import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: '/papers',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <AllPapers /> }]
    },
    {
      path: '/papers/:provider/:exam',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <AllPapers /> }]
    },
    {
      path: '/papers/:provider/:exam/:page',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <AllPapers /> }]
    },
    {
      path: '/practice-test',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <PracticeTest /> }]
    },
    {
      path: '/practice-test/:provider/:exam',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <PracticeTest /> }]
    },
    {
      path: '/referral',
      element: <DashboardLayout />,
      children: [{ path: '/', element: <Referral /> }]
    },
    {
      path: '/redeem-reward',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '/', element: <RedeemReward /> }]
    },
    {
      path: '/practice-test',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'buy', element: <PracticeTest /> }]
    },

    {
      path: '/papers',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'buy', element: <AllPapers /> },
        { path: 'buy-offline', element: <EcommerceCheckout /> }
      ]
    },
    {
      path: '/user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/account', element: <UserAccount /> },
        { path: '/paper-list', element: <UserList /> }
      ]
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/paper-access', element: <AdminPaperAccess /> },
        // { path: '/users-dashboard', element: <UsersDashboard /> },
        { path: '/paper-dashboard', element: <GeneralApp /> },
        { path: '/transfer-account', element: <TransferAccount /> },
        { path: '/vouchers', element: <AdminVouchers /> }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/papers" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        // { path: 'referral', element: <Referral/> },
        { path: 'request', element: <NewPaperRequest /> },
        { path: 'exams', element: <Exams /> },
        { path: 'exams/:name', element: <Exam /> },
        { path: 'feedback', element: <Feedback /> },
        { path: 'plan', element: <Plan /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacypolicy', element: <Privacy /> },
        { path: 'premium-access', element: <PaymentMethod /> },
        { path: 'faq-practice-test', element: <PracticeTestFAQ /> },
        { path: 'tos', element: <Terms /> },
        { path: 'request', element: <NewPaperRequest /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UsersDashboard = Loadable(lazy(() => import('../pages/dashboard/users/Dashboard')));
const AllPapers = Loadable(lazy(() => import('../pages/dashboard/allPapers/AllPapers')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const RedeemReward = Loadable(lazy(() => import('../pages/dashboard/RedeemReward')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Referral = Loadable(lazy(() => import('../pages/Referral')));
const Feedback = Loadable(lazy(() => import('../pages/Feedback')));
const Plan = Loadable(lazy(() => import('../pages/Plan')));
const Exams = Loadable(lazy(() => import('../pages/Exams')));
const Exam = Loadable(lazy(() => import('../pages/Exam')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const PaymentMethod = Loadable(lazy(() => import('../pages/PaymentMethod')));
const PracticeTestFAQ = Loadable(lazy(() => import('../pages/PracticeTestFAQ')));
const Terms = Loadable(lazy(() => import('../pages/Terms')));
const NewPaperRequest = Loadable(lazy(() => import('../pages/NewPaperRequest')));
// Components

const AdminPaperAccess = Loadable(lazy(() => import('../pages/admin/Access/PapersAccess')));
const PracticeTest = Loadable(lazy(() => import('../pages/dashboard/testPaper/PracticeTest')));
const AdminVouchers = Loadable(lazy(() => import('../pages/admin/Access/vouchers/Vouchers')));

const TransferAccount = Loadable(lazy(() => import('../pages/admin/Access/transferAccount/TransferAccount')));
